// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import { createApp } from 'vue/dist/vue.esm-bundler';  
import ButtonCounter from '../components/ButtonCounter.vue'
import Leaderboard from '../components/Leaderboard.vue'
import App from '../components/App.vue'
import Timer from '../components/Timer.vue'
import '../add_jquery.js'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import 'bootstrap/js/src/button'
  
const app = createApp({  
  data() {  
    return {  
      course: 'Intro to Vue 3 and Rails'  
    }  
  }  
})  

app.component('ButtonCounter', ButtonCounter)
app.component('Leaderboard', Leaderboard)
app.component('Timer', Timer)
app.component('App', App)



app.mount('#app');  


console.log("app", app);  