<template>

    <div class="card d-flex w-75 mx-auto">
        <h5 class="card-header">
            <a data-bs-toggle="collapse show" @click="toggleMenu()" aria-expanded="false" aria-controls="collapse-example" id="heading-example" class="d-block text-start">
                <i class="fa fa-chevron-down pull-right"></i>
                Stats
            </a>
        </h5>
        <div id="collapse-example" class="collapse" aria-labelledby="heading-example">
            <div class="card-body">
                <Scatter
                        id="my-chart-id"
                        :options="chartOptions"
                        :data="chartData"
                    />
            </div>
        </div>
    </div>
</template>
  
  <script>
  import { Scatter } from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, Legend, PointElement, LineElement, CategoryScale, LinearScale, TimeScale } from 'chart.js'
  import 'chartjs-adapter-date-fns';
  
  ChartJS.register(Title, Tooltip, Legend, PointElement, LineElement, CategoryScale, LinearScale, TimeScale)
  
  export default {
    name: 'LineChart',
    components: { Scatter },
    data() {
       gon.watch('trackers', {interval: 5000}, this.updateChart);
        var events = gon.trackers
        console.log(events)
        var start = new Date('July 5, 2024, 18:00:00-04:00')

        return {
            chartData: {
                    datasets: [ {
                        label: "a",
                        data: [{x: 0, y: 0}, {x: 1, y:1}] } ]
                },
            chartOptions: {
                scales: {
                    y: {
                        title: {
                            display: true,
                            text: "Hours on timer",
                        },
                    },
                    x: {
                        type: 'time',
                        parsing: false,
                        time: {
                            displayFormats: {
                                millisecond: 'MMM dd hh:mm',
                                second: 'MMM dd hh:mm',
                                minute: 'MMM dd hh:mm',
                                hour: 'MMM dd hh:mm',
                                day: 'MMM dd hh:mm',
                                week: 'MMM dd hh:mm',
                                month: 'MMM dd hh:mm',
                                quarter: 'MMM dd hh:mm',
                                year: 'MMM dd hh:mm',
                                quarter: 'MMM dd hh:mm',
                            }
                        }
                    },
                }
            }
        }
    },
    methods: {
        toggleMenu() {
        try {
            let el = document.getElementById('collapse-example');
            if (el) {
            el.classList.toggle('show');
            }
        } catch (error) {
            console.error(error);
        }
        },
        updateChart(events) {
            var start = new Date('July 5, 2024, 18:00:00-04:00')
            var time = [] //seconds
            var data = [] //minutes
            var gifted_per_user = {}
            /*
                + 1*60*60 //bao time wheel
               + 2*60*60 //bryn time wheel
               + 1*60*60 //peach and honey
               + 4*60*60 //bryn time wheel
               + 1*60*60 + 40*60 //peach sub
               + 1*60*60 // rocketthumb time wheel
               + 1*60*60 // bao time wheel

            */
            var time_wheels = [ 

            ]
            var tw_idx = 0
            var pauses = [
                         ]
            for(const gift of events) {
                if (false && tw_idx <= 6 && time_wheels[tw_idx].time < gift.time){
                    // console.log("Adding time wheel:")
                    // console.log(time_wheels[tw_idx])
                    // console.log(gift.time)
                    data.push(time_wheels[tw_idx].time_added)
                    time.push(time_wheels[tw_idx].time)
                    tw_idx++
                }
                data.push (4*gift.t1 + 8*gift.t2 + 20*gift.t3 + 1*Math.floor(gift.bits/100))
                time.push(gift.time)
                if (gift.username in gifted_per_user) {
                    gifted_per_user[gift.username]++
                } else {
                    gifted_per_user[gift.username] = 1
                }
                if (gifted_per_user[gift.username] == 5) {
                    data.push(5)
                    // console.log("Adding 5 minutes yippeeeeeee")
                    time.push(gift.time)
                }
            }
            var currTime = new Date().getTime()/1000
            time.push(currTime) 
            data.push(0)
            // console.log(time)
            // console.log(data)
            var points = [start.getTime()/1000] //seconds
            var chart_data = [4] //hours
            var accumulated_time = 0
            var pause_idx = 0
            var in_pause = false
            var prev_pause = null
            for(var i = 0; i < time.length; i++) {
                // console.log("IN LOOP")
                var curr_pause = null
                if (pause_idx <= 11) {
                    curr_pause = pauses[pause_idx]
                }
                var lastTime = points[points.length-1]
                var lastData = chart_data[chart_data.length-1]

                if(curr_pause && curr_pause.start < time[i]) { //handle pause start
                    // add a pause point
                    // console.log("handling pause start")
                    points.push(curr_pause.start)
                    var elapsedTime = (curr_pause.start - lastTime)/(60*60)
                    chart_data.push(lastData - elapsedTime)
                    lastData = lastData - elapsedTime
                    lastTime = curr_pause.start
                    prev_pause = curr_pause
                    in_pause = true
                    pause_idx++
                }
                if(prev_pause && in_pause && prev_pause.end < time[i]) { //handle pause end
                    // console.log("Handling Pause end for:" + prev_pause.end)
                    points.push(prev_pause.end)
                    // var elapsedTime = (prev_pause.end - lastTime)/(60*60)
                    chart_data.push(lastData)
                    lastTime = prev_pause.end
                    in_pause = false
                }
                if(time[i] == points[points.length-1]) {
                    chart_data[chart_data.length-1] += data[i]/60
                } else {
                    points.push(time[i])
                    points.push(time[i])
                    var elapsedTime = (time[i] - lastTime)/(60*60)
                    // for(const times of pauses) {
                    //     if (lastTime > times.start && time[i] <= times.end) {
                    //         elapsedTime = 0;
                    //         console.log("first")
                    //         console.log(elapsedTime)
                    //         break;
                    //     // } 
                    //     // else if(time[i] >= times.start && time[i] <= times.end && lastTime <= times.start) {
                    //     //     var distToStart = times.start-lastTime
                    //     //     elapsedTime = (distToStart)/60*60
                    //     //     console.log("second")
                    //     //     console.log(elapsedTime)
                    //     //     break
                    //     } else if(time[i] >= times.start && lastTime <= times.end) { //overlaps a paused interval
                    //         var distToStart = Math.max(times.start-lastTime, 0)
                    //         var distFromEnd = Math.max(time[i] - times.end, 0)
                    //         elapsedTime = (distToStart + distFromEnd)/(60*60)
                    //         console.log("third")
                    //         console.log(elapsedTime)
                    //         break
                    //     }
                    // }
                    var giftedTime = data[i]/60
                    console.log("gifted")
                    console.log(giftedTime)
                    console.log("elapsed")
                    console.log(elapsedTime)
                    var lowData = (lastData - elapsedTime)
                    if (in_pause) lowData = lastData
                    var highData = lowData + giftedTime
                    if (lowData < 0) {
                        lowData = 0
                    }

                    if (highData < 0) {
                        highData = 0
                    }
                    // console.log(lowData)
                    // console.log(highData)
                    // var newData = (lastData - elapsedTime/60) + giftedTime
                    chart_data.push(lowData)
                    chart_data.push(highData)
                }
            }


            // if (chart_data[chart_data.length-1] < 0) {
            //     chart_data[chart_data.length-1] = 0
            // }

            var realChartData = []
            var labels = []
            for(var i = 0; i < points.length; i++) {
                labels.push(new Date(points[i]*1000))
                var label = new Date(points[i]*1000)
                realChartData.push({x: label, y: chart_data[i]})
            }
            this.chartData = {
                    // labels: labels,
                    label: "Timer",
                    datasets: [{
                        label: "Timer",
                        backgroundColor: '#335ac3',
                        borderColor: '#bf335ac3',
                        showLine: true,
                        data: realChartData
                    }]
                }

        }
    }
  }
  </script>