<script>
export default {  
  data() {  
    gon.watch('gifters', {interval: 1000}, this.updateLB);
   return {  
    gifters: gon.gifters,
    sorted: 0
   }
  },
   methods: {
    sortFunction(a, b) {
        if (this.sorted == 0) {
            return b.subs-a.subs 
        } else if(this.sorted == 1) {
            return b.bits - a.bits
        } else {
            var btime = 3*60*b.t1 + 6*60*b.t2 + 20*60*b.t3 + 1*60*Math.floor(b.bits/100)
            var atime = 3*60*a.t1 + 6*60*a.t2 + 20*60*a.t3 + 1*60*Math.floor(a.bits/100)
            return btime - atime
        }
    },
    updateLB(newGifters) {
        if(newGifters) 
            this.gifters = newGifters.sort(this.sortFunction)
    },
    setButtonState(state) {
        this.sorted = state
        this.gifters = this.gifters.sort(this.sortFunction)
    },
    convertTime(sec_num) {
            // var sec_num = parseInt(timeInSeconds, 10); // don't forget the second param
            var hours   = sec_num / 3600;
            if (hours >= 1) {
                return hours.toFixed(1) + " Hours Added"
            } 
            var minutes = Math.floor((sec_num - (Math.floor(hours) * 3600)) / 60);
            return minutes + " Minutes Added"
}
   }  
  }  
  
</script>  
  
<template>  




<div class="w-75 mx-auto">
    <div class="row">
        <div class="col-sm-6">
            <div class="h1 text-light">Leaderboard</div>
        </div>
    </div>

    <div class="btn-group" role="group" aria-label="Basic example">
        <button type="button" v-bind:class = "(sorted == 0)? 'btn btn-primary active' : 'btn btn-primary '" @click="setButtonState(0)" v-bind:style = "(sorted == 0) ? 'background-color:#2636AD' : 'background-color:#335AC3'">
        Sort by Subs
        </button>
        <button type="button" v-bind:class = "(sorted == 1)? 'btn btn-primary active' : 'btn btn-primary'" @click="setButtonState(1)" v-bind:style = "(sorted == 1) ? 'background-color:#2636AD' : 'background-color:#335AC3'">
        Sort by Bits
        </button>
        <button type="button" v-bind:class = "(sorted == 2)? 'btn btn-primary active' : 'btn btn-primary'" @click="setButtonState(2)" v-bind:style = "(sorted == 2) ? 'background-color:#2636AD' : 'background-color:#335AC3'">
        Sort by Time Added
        </button>
    </div>

  <table class="table table-striped table-borderless table-hover table-responsive">
    <tbody>
        <tr v-for="index in gifters.length" :key="index">
            <td class="text-end" :class="(index > 3) ? 'text-light' : ''" style="width: 5%" :style=" (index == 1) ? 'color:#6de7c6' : (index == 2) ? 'color:#9dfad7' : (index == 3) ? 'color:#befffe' : ''">{{index}}.</td>
            <td class="" :class="(index > 3) ? 'text-light' : ''" :style="(index == 1) ? 'color:#6de7c6' : (index == 2) ? 'color:#9dfad7' : (index == 3) ? 'color:#befffe' : ''">{{gifters[index-1].username}}</td>
            <td class="" :class="(index > 3) ? 'text-light' : ''" :style="(index == 1) ? 'color:#6de7c6' : (index == 2) ? 'color:#9dfad7' : (index == 3) ? 'color:#befffe' : ''">{{gifters[index-1].subs}} Subs</td>
            <td class="" :class="(index > 3) ? 'text-light' : ''" :style="(index == 1) ? 'color:#6de7c6' : (index == 2) ? 'color:#9dfad7' : (index == 3) ? 'color:#befffe' : ''">{{gifters[index-1].bits}} Bits</td>
            <td class="" :class="(index > 3) ? 'text-light' : ''" :style="(index == 1) ? 'color:#6de7c6' : (index == 2) ? 'color:#9dfad7' : (index == 3) ? 'color:#befffe' : ''">{{convertTime(3*60*gifters[index-1].t1 + 6*60*gifters[index-1].t2 + 20*60*gifters[index-1].t3 + 1*60*Math.floor(gifters[index-1].bits/100))}}</td>
        </tr>
    </tbody>
  </table>
</div>
</template>  
