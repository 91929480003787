<template> 
    <div>
        <timer></timer>
        <LineChart></LineChart>
        <Leaderboard></Leaderboard>
    </div>
</template>

<script>
import Timer from './Timer.vue'
import LeaderboardVue from './Leaderboard.vue';
import Leaderboard from './Leaderboard.vue';
import LineChart from './Stats.vue'

export default {
    components: {
    "timer": Timer,
    "Leaderboard": Leaderboard,
    "LineChart": LineChart,
    Leaderboard
},
    data: function() {
        return {
            message: "Hello Vue!"
        }
    }
}
</script>

<style scoped>
p {
    font-size: 2em;
    text-align: center;
}
</style>